import * as React from "react";
import { Notification, useLogin, useNotify } from "react-admin";
import { Paper, Typography, Button, TextField, Box, Stack } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { Link as RouterLink } from "react-router-dom";
import logoImage from '../public/icon.png';

const StyledLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: "none",
  textAlign: "left",
  flexGrow: 1,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: 400,
  margin: 'auto',
  marginTop: theme.spacing(2),
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(4),
}));

const Logo = styled('img')({
  height: 160,
  width: 'auto',
});

export const LoginPage = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const login = useLogin();
  const notify = useNotify();

  const handleSubmit = e => {
    e.preventDefault();
    login({ email, password }).catch(() =>
      notify("Invalid email or password", { type: "error" })
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          bgcolor: 'background.default',
          py: 4,
        }}
      >
        <Box sx={{ maxWidth: 400, width: '100%' }}>
          <LogoContainer>
            <Logo src={logoImage} alt="Logo" />
          </LogoContainer>
          
          <StyledPaper elevation={3}>
            <Typography variant="h4" align="center" gutterBottom>
              Login
            </Typography>
            <form onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <TextField
                  name="email"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  fullWidth
                  required
                  autoFocus
                />
                <TextField
                  name="password"
                  type="password"
                  label="Password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  fullWidth
                  required
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <StyledLink to="/forgot">
                    Forgot Password?
                  </StyledLink>
                  <StyledLink to="/signup">
                    Sign Up
                  </StyledLink>
                </Box>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  size="large"
                  sx={{ mt: 2 }}
                >
                  Login
                </Button>
              </Stack>
            </form>
            <Notification />
          </StyledPaper>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
