import React from "react";
import { Admin, Resource, CustomRoutes } from 'react-admin';
import { BrowserRouter } from 'react-router-dom';
import { theme } from "./theme.js";
import Dashboard from "./resources/dashboard/index.jsx";
import { authProvider } from "./providers/auth.js";
import { client } from "./providers/client.js";
import { dataProvider } from "./providers/index.js";
import { LoginPage } from "./login.jsx";
import { getResources } from "./resources/index.js";
import { ForgotPasswordPage } from "./forgot.jsx";
import { SignUpPage } from "./signup.jsx";
import { Route } from "react-router-dom";
import { CustomLayout } from './layout/index.jsx';

/**
 * Main application component that sets up react-admin with authentication,
 * custom routes, and resource configuration.
 * 
 * Features:
 * - Custom authentication with login, signup, and password recovery
 * - Dynamic resource loading based on user permissions
 * - Custom theme and layout
 * - Dashboard integration
 */
const App = () => {
  // Memoize the auth provider to prevent unnecessary re-renders
  const auth = React.useMemo(() => authProvider(client), []);
  
  // Memoize the data provider to prevent unnecessary re-renders
  const data = React.useMemo(() => dataProvider, []);

  return (
    <BrowserRouter>
      <Admin
        title="Turbo"
        dashboard={Dashboard}
        authProvider={auth}
        dataProvider={data}
        loginPage={LoginPage}
        layout={CustomLayout}
        theme={theme}
        requireAuth={false}
        disableTelemetry
        basename=""
      >
        {/* Public routes that don't require authentication */}
        <CustomRoutes noLayout>
          <Route path="/forgot" element={<ForgotPasswordPage />} />
          <Route path="/signup" element={<SignUpPage />} />
        </CustomRoutes>

        {/* Dynamic resources based on user permissions */}
        {user => getResources(user).map(resource => (
          <Resource 
            key={resource.name} 
            {...resource} 
          />
        ))}
      </Admin>
    </BrowserRouter>
  );
};

export default App;
