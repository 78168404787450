import {
    Create,
    TextInput,
    RadioButtonGroupInput,
    required,
    SimpleForm,
    FormDataConsumer,
    SelectInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    useNotify,
    useRedirect,
    Button,
    TopToolbar,
    minLength,
    maxLength,
    useTranslate,
    regex,
    FileInput,
    FileField,
    NumberInput,
    SaveButton,
    Toolbar,
    useCreate,
} from "react-admin";
import { BulkSendMethods } from "../../constants";
import {
    Box,
    Typography,
    Stack,
    Paper,
    Stepper,
    Step,
    StepLabel,
    CircularProgress,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState, useEffect } from "react";
import { useFormContext } from 'react-hook-form';

const CreateActions = () => (
    <TopToolbar>
        <Button
            label="Back to List"
            onClick={() => window.history.back()}
            icon={<ArrowBackIcon />}
        />
    </TopToolbar>
);

const SendToolbar = () => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [create] = useCreate();
    const { getValues } = useFormContext(); // Get access to form values


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsSubmitting(true);
            const formValues = getValues(); // Get all form values including tags
            console.log('Form values from context:', formValues);

            const formData = new FormData();
            const formDataObj = { ...formValues }; // Create a copy of form values
            
            console.log('Form data before validation:', formDataObj);

            // Validate based on bulkSelection
            if (formDataObj.bulkSelection === 'One') {
                if (!formDataObj.phoneNumber) {
                    throw new Error('Phone number is required for single operations');
                }
                delete formDataObj.tags;
            } else if (formDataObj.bulkSelection === 'Many') {
                if (!formDataObj.tags || formDataObj.tags.length === 0) {
                    throw new Error('Tags are required for multiple operations');
                }
                delete formDataObj.phoneNumber;
            }

            // Add form values to FormData
            Object.entries(formDataObj).forEach(([key, value]) => {
                if (value !== undefined) {
                    if (key === 'tags' && Array.isArray(value)) {
                        // Ensure tags are sent as a JSON string array
                        const tagsArray = value.map(tag => tag.id || tag);
                        console.log('Sending tags:', tagsArray);
                        formData.append('tags', JSON.stringify(tagsArray));
                    } else {
                        formData.append(key, value);
                    }
                }
            });

            console.log('Final form data:', Object.fromEntries(formData));

            // Send the FormData
            await create('bulk', {
                data: formData,
            }, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            notify('Bulk operation submitted successfully', { type: 'success' });
            redirect('list', 'bulk');
        } catch (error) {
            notify(error?.message || 'Error submitting bulk operation', { type: 'error' });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Toolbar
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                p: 2,
                backgroundColor: 'background.paper',
                borderTop: 1,
                borderColor: 'divider',
                position: 'sticky',
                bottom: 0,
                zIndex: 1,
            }}
        >
            <Button
                label="Cancel"
                onClick={() => window.history.back()}
                icon={<ArrowBackIcon />}
                color="inherit"
                disabled={isSubmitting}
            />
            <SaveButton
                label={isSubmitting ? "Sending..." : "Send"}
                onClick={handleSubmit}
                icon={isSubmitting ? <CircularProgress size={18} thickness={2} /> : <SendIcon />}
                disabled={isSubmitting}
                type="submit"
                alwaysEnable
            />
        </Toolbar>
    );
};

const validateMessage = [
    required('Message is required'),
    minLength(10, 'Message must be at least 10 characters'),
    maxLength(160, 'Message must not exceed 160 characters')
];

const validatePhoneNumber = [
    required('Phone number is required'),
    regex(/^\+?[1-9]\d{1,14}$/, 'Invalid phone number format')
];

const validateAmount = [
    required('Amount is required'),
    minLength(1, 'Amount must be at least 1'),
    maxLength(100000, 'Amount must not exceed 100000')
];

const validateAudioFile = (value) => {
    if (!value) {
        return 'Audio file is required';
    }

    // If it's a new file upload
    if (value.rawFile) {
        const file = value.rawFile;

        // Check file type
        if (!file.type.startsWith('audio/')) {
            return 'Please upload an audio file';
        }

        // Check file size (e.g., 50MB limit)
        const maxSize = 50 * 1024 * 1024; // 50MB in bytes
        if (file.size > maxSize) {
            return 'File size should be less than 50MB';
        }
    }

    return undefined; // Validation passed
};

const BulkFormStepper = ({ step }) => (
    <Stepper activeStep={step} sx={{ mb: 4 }}>
        <Step completed={step > 0}>
            <StepLabel>Select Operation Type</StepLabel>
        </Step>
        <Step completed={step > 1}>
            <StepLabel>Select Method</StepLabel>
        </Step>
        <Step completed={step > 2}>
            <StepLabel>Configure Recipients</StepLabel>
        </Step>
        <Step completed={step > 3}>
            <StepLabel>Message Content</StepLabel>
        </Step>
    </Stepper>
);

const MessageContent = ({ method }) => {
    switch (method) {
        case 'SMS':
        case 'WhatsApp':
            return (
                <TextInput
                    source="message"
                    multiline
                    rows={4}
                    fullWidth
                    validate={validateMessage}
                    helperText="Enter your message content (10-160 characters)"
                    placeholder="Type your message here..."
                />
            );
        case 'Airtime':
            return (
                <>
                    <NumberInput
                        source="amount"
                        validate={validateAmount}
                        fullWidth
                        helperText="Enter airtime amount (1-10000)"
                        min={1}
                        max={10000}
                    />
                    <FileInput
                        source="csvFile"
                        label="Upload CSV File (optional)"
                        accept=".csv"
                        placeholder="Drag and drop a CSV file or click to select"
                    >
                        <FileField source="src" title="title" />
                    </FileInput>
                </>
            );
        case 'Voice':
            return (
                <FileInput
                    source="audioFile"
                    label="Upload Audio File"
                    accept="audio/*"
                    validate={validateAudioFile}
                    placeholder="Drag and drop an audio file or click to select"
                    parse={(value) => {
                        if (!value) return null;
                        if (value.rawFile instanceof File) {
                            return value.rawFile;
                        }
                        return value;
                    }}
                >
                    <FileField source="src" title="title" />
                </FileInput>
            );
        default:
            return null;
    }
};

export const CreateBulk = (props) => {
    const [activeStep, setActiveStep] = useState(0);
    const translate = useTranslate();
    const [bulkType, setBulkType] = useState('One');

    const handleFormChange = (formData) => {
        let step = 0;

        // Step 1: Operation Type
        if (formData.bulkSelection) {
            step = 1;

            // Step 2: Method
            if (formData.method) {
                step = 2;

                // Step 3: Recipients
                if ((formData.bulkSelection === 'One' && formData.phoneNumber) ||
                    (formData.bulkSelection === 'Many' && formData.tags?.length > 0)) {
                    step = 3;

                    // Step 4: Content
                    if ((formData.method === 'SMS' && formData.message?.length >= 10) ||
                        (formData.method === 'WhatsApp' && formData.message?.length >= 10) ||
                        (formData.method === 'Airtime' && formData.amount) ||
                        (formData.method === 'Voice' && formData.audioFile)) {
                        step = 4;
                    }
                }
            }
        }

        setActiveStep(step);
    };

    const handleBulkTypeChange = (value) => {
        setBulkType(value);
    };

    return (
        <Create actions={<CreateActions />} {...props}>
            <SimpleForm
                toolbar={<SendToolbar />}
                sx={{
                    width: '100%',
                    '& .RaSimpleFormInput-input': {
                        marginBottom: 0,
                    }
                }}
                defaultValues={{ bulkSelection: 'One' }}
                onChange={handleFormChange}
            >
                <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                    Create Bulk Operation
                </Typography>

                <BulkFormStepper step={activeStep} />

                <Stack spacing={3}>
                    {/* Step 1: Operation Type */}
                    <Paper elevation={2} sx={{ p: 2 }}>
                        <Typography variant="subtitle1" gutterBottom color="primary">
                            Operation Type
                        </Typography>
                        <RadioButtonGroupInput
                            source="bulkSelection"
                            choices={[
                                { id: "One", name: "Single Operation" },
                                { id: "Many", name: "Multiple Operations" }
                            ]}
                            value={bulkType}
                            onChange={(event) => {
                                const value = event.target.value;
                                handleBulkTypeChange(value);
                            }}
                            sx={{ mb: 0 }}
                        />
                    </Paper>

                    <FormDataConsumer>
                        {({ formData }) => (
                            <>
                                {/* Step 2: Method Selection - Only show if operation type is selected */}
                                {formData.bulkSelection && (
                                    <Paper elevation={2} sx={{ p: 2 }}>
                                        <Typography variant="subtitle1" gutterBottom color="primary">
                                            Send Method
                                        </Typography>
                                        <SelectInput
                                            label="Method"
                                            source="method"
                                            // defaultValue="SMS"
                                            choices={BulkSendMethods}
                                            fullWidth
                                            sx={{ mb: 2 }}
                                        />
                                    </Paper>
                                )}

                                {/* Step 3: Recipients - Only show if method is selected */}
                                {formData.method && (
                                    formData.bulkSelection === "One" ? (
                                        <Paper elevation={2} sx={{ p: 2 }}>
                                            <Typography variant="subtitle1" gutterBottom color="primary">
                                                Recipient Phone Number
                                            </Typography>
                                            <TextInput
                                                source="phoneNumber"
                                                validate={validatePhoneNumber}
                                                fullWidth
                                                helperText="Enter the recipient's phone number (e.g., +25672300400)"
                                                placeholder="+25678*******"
                                            />
                                        </Paper>
                                    ) : (
                                        <Paper elevation={2} sx={{ p: 2 }}>
                                            <Typography variant="subtitle1" gutterBottom color="primary">
                                                Participant Tags
                                            </Typography>
                                            <ReferenceArrayInput
                                                source="tags"
                                                reference="tags"
                                                // filter={{ active: true }}
                                                sort={{ field: 'name', order: 'ASC' }}
                                            >
                                                <AutocompleteArrayInput
                                                    optionText="name"
                                                    optionValue="id"
                                                    fullWidth
                                                    validate={required('At least one tag is required')}
                                                    helperText="Select one or more participant tags"
                                                    placeholder="Start typing to search tags..."
                                                    createLabel="Add tag"
                                                    parse={value => {
                                                        console.log('Parsing value:', value);
                                                        return value;
                                                    }}
                                                    format={value => {
                                                        console.log('Formatting value:', value);
                                                        return value;
                                                    }}
                                                    sx={{ mb: 1 }}
                                                    filterToQuery={searchText => ({ q: searchText })}
                                                    debounce={300}
                                                    suggestionLimit={10}
                                                />
                                            </ReferenceArrayInput>
                                            <FormDataConsumer>
                                                {({ formData }) => formData.tags && (
                                                    <Typography variant="caption" color="textSecondary">
                                                        Selected tags: {formData.tags.length}
                                                    </Typography>
                                                )}
                                            </FormDataConsumer>
                                        </Paper>
                                    )
                                )}

                                {/* Step 4: Content - Only show if recipients are configured */}
                                {((formData.bulkSelection === 'One' && formData.phoneNumber) ||
                                    (formData.bulkSelection === 'Many' && formData.tags?.length > 0)) && (
                                        <Paper elevation={2} sx={{ p: 2 }}>
                                            <Typography variant="subtitle1" gutterBottom color="primary">
                                                {formData.method === 'Airtime' ? 'Amount & Recipients File' :
                                                    formData.method === 'Voice' ? 'Audio File' :
                                                        'Message Content'}
                                            </Typography>
                                            <MessageContent method={formData.method} />
                                            {(formData.method === 'SMS' || formData.method === 'WhatsApp') && (
                                                <FormDataConsumer>
                                                    {({ formData }) => formData.message && (
                                                        <Typography variant="caption" color="textSecondary" sx={{ mt: 1, display: 'block' }}>
                                                            Character count: {formData.message.length}/160
                                                        </Typography>
                                                    )}
                                                </FormDataConsumer>
                                            )}
                                        </Paper>
                                    )}
                            </>
                        )}
                    </FormDataConsumer>
                </Stack>
            </SimpleForm>
        </Create>
    );
};
