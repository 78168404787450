import { useState, useEffect } from 'react';
import { getCurrentUser } from './auth';

export const useBalance = (dataProvider) => {
    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);

    const fetchBalance = async () => {
        try {
            const user = await getCurrentUser();
            if (!user) {
                console.warn('No user found');
                setBalance(0);
                return;
            }

            setCurrentUser(user);
            
            if (!user.id) {
                console.warn('User has no ID:', user);
                setBalance(0);
                return;
            }

            setLoading(true);
            // console.log('Fetching balance for user ID:', user.id);

            const response = await dataProvider.getOne('usage/user', {
                id: user.id
            });
            
            // console.log('Balance response:', response);
            
            const currentBalance = response?.data?.amount;
            
            if (currentBalance === undefined || currentBalance === null) {
                console.warn('Invalid balance received:', response);
                setBalance(0);
            } else {
                setBalance(currentBalance);
                console.log('Balance updated successfully:', currentBalance);
            }
        } catch (error) {
            console.error('Error fetching balance:', error);
            setError(error);
            setBalance(0);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBalance();
        
        // Set up polling interval
        const interval = setInterval(fetchBalance, 30000);
        
        // Cleanup function
        return () => {
            console.log('Cleaning up balance polling interval');
            clearInterval(interval);
        };
    }, [dataProvider]); // Only depend on dataProvider

    return { 
        balance, 
        loading, 
        error,
        fetchBalance,
        currentUser,
        hasUser: !!currentUser?.id
    };
};