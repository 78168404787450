import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_GET_PERMISSIONS
} from "react-admin";
import  { jwtDecode } from "jwt-decode";

const authActions = {
  [AUTH_ERROR]: authError,
  [AUTH_LOGIN]: authLogin,
  [AUTH_LOGOUT]: authLogout,
  [AUTH_CHECK]: authCheck,
  [AUTH_GET_PERMISSIONS]: authPermissions
};

export function authProvider(client) {
  return (type, params) => {
    if (type === 'getUserIdentity') {
      return getUserIdentity();
    }
    const action = authActions[type];
    return action(params, client);
  };
}

function authCheck(_params) {
  const token = localStorage.getItem("token");

  if (!token) {
    return Promise.reject();
  }

  return Promise.resolve();
}

function authError(error) {
  if (error.status === 401) {
    return Promise.reject();
  }

  return Promise.resolve();
}

function authLogin(params, client) {
  const { email, password } = params;
  return client
    .post(`/auth/login`, { email, password })
    .then(response => response.data)
    .then(({ token }) => {
      const decodedToken = jwtDecode(token);
      localStorage.setItem("token", token);
      localStorage.setItem('user_id', decodedToken.id);
    });
}

function authLogout(_params, _client) {
  localStorage.removeItem("token");
  localStorage.removeItem("user_id");
  return Promise.resolve();
}

function jsonToken() {
  const token = localStorage.getItem("token");
  const decodedToken = token ? jwtDecode(token) : null;
  return decodedToken;
}

function authPermissions() {
  const user = jsonToken();
  const isSignupOrForgot = /(forgot|signup)/.test(window.location.hash);

  // return user ? Promise.resolve(user) : Promise.reject();

  if (!user && !isSignupOrForgot) {
    return Promise.reject({});
  }

  return Promise.resolve(user || { "role": "guest" });
}

// New getUserIdentity function
async function getUserIdentity() {
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");

  if (!token || !userId) {
    return Promise.reject();
  }

  try {
    const decodedToken = jwtDecode(token);
    
    if (!decodedToken || !decodedToken.id) {
      console.error('Invalid token or missing user ID in token');
      return Promise.reject();
    }

    // Return the user identity object
    return Promise.resolve({
      id: decodedToken.id,
      fullName: decodedToken.fullName || decodedToken.email,
      avatar: decodedToken.avatar,
      // Add any other user properties from your token
      email: decodedToken.email,
      role: decodedToken.role
    });
  } catch (error) {
    console.error('Error decoding token:', error);
    return Promise.reject();
  }
}

// Export additional helper function if needed
export function getCurrentUser() {
  return getUserIdentity().catch(() => null);
}