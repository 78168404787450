import React from 'react';
import { AppBar, Layout, useGetIdentity, useDataProvider } from 'react-admin';
import { Box, Typography, Chip, CircularProgress } from '@mui/material';
import { AccountBalanceWallet as WalletIcon } from '@mui/icons-material';
import { useBalance } from '../providers/balanceProvider';
import { getCurrentUser } from '../providers/auth'; // Updated import path

const CustomAppBar = () => {
    const { identity, isLoading: identityLoading } = useGetIdentity();
    const dataProvider = useDataProvider();
    const { balance, loading: balanceLoading } = useBalance(dataProvider);

    const [currentUser, setCurrentUser] = React.useState(null);
    const [userLoading, setUserLoading] = React.useState(true);

    React.useEffect(() => {
        const loadUser = async () => {
            try {
                const user = await getCurrentUser();
                setCurrentUser(user);
            } catch (error) {
                console.error('Error loading user:', error);
            } finally {
                setUserLoading(false);
            }
        };

        loadUser();
    }, []);

    // console.log('Current user:', currentUser);
    // console.log('User loading:', userLoading);
    // console.log('Balance loading:', balanceLoading);
    // console.log('Balance:', balance);

    return (
        <AppBar>
            <Box flex={1} />
            <Box 
                display="flex" 
                alignItems="center" 
                gap={2}
                sx={{ mr: 2 }}
            >
                <Chip
                    icon={<WalletIcon />}
                    label={
                        balanceLoading || userLoading ? (
                            <CircularProgress size={16} color="inherit" />
                        ) : (
                            <Typography variant="body2">
                                Balances: {balance?.toLocaleString() || '0'} UGX
                            </Typography>
                        )
                    }
                    color="primary"
                    variant="outlined"
                />
            </Box>
        </AppBar>
    );
};

export const CustomLayout = (props) => (
    <Layout {...props} appBar={CustomAppBar} />
);
