import { defaultTheme } from 'react-admin';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    ...defaultTheme,
    palette: {
        mode: 'light',
        primary: {
            main: '#5191b7',
        },
        secondary: {
            main: '#eef0f3',
        },
        background: {
            default: '#f5f5f5',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                },
            },
        },
        RaMenuItemLink: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                },
            },
        },
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontWeight: 500,
            fontSize: '2rem',
        },
        h2: {
            fontWeight: 500,
            fontSize: '1.5rem',
        },
    },
});
