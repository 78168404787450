import {
    List,
    TextField,
    ReferenceField,
    DateField,
    SearchInput,
    SelectInput,
    FilterButton,
    CreateButton,
    TopToolbar,
    WrapperField,
    useRecordContext,
    Datagrid,
    NumberField,
} from "react-admin";
import { Box, Tooltip, Chip, Typography } from "@mui/material";
import { BulkSendMethods } from "../../constants";

const ListActions = () => (
    <TopToolbar>
        <FilterButton />
        <CreateButton />
    </TopToolbar>
);

const listFilters = [
    <SearchInput source="q" alwaysOn />,
    <SelectInput 
        source="method" 
        choices={BulkSendMethods}
        sx={{ minWidth: 200 }}
    />,
    <SelectInput 
        source="status" 
        choices={[
            { id: 'pending', name: 'Pending' },
            { id: 'sent', name: 'Sent' },
            { id: 'failed', name: 'Failed' },
        ]}
        sx={{ minWidth: 200 }}
    />,
];

const StatusField = () => {
    const record = useRecordContext();
    if (!record) return null;

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'submmitted':
                return 'default';
            case 'success':
                return 'success';
            case 'pending':
                return 'warning';
            case 'failed':
                return 'error';
            default:
                return 'default';
        }
    };

    return (
        <Chip
            label={record.status}
            color={getStatusColor(record.status)}
            variant="outlined"
            size="small"
            sx={{
                fontWeight: 'medium',
                textTransform: 'capitalize'
            }}
        />
    );
};

const ContentField = () => {
    const record = useRecordContext();
    if (!record) return null;

    return (
        <Tooltip title={record.content} arrow>
            <Typography
                variant="body2"
                sx={{
                    maxWidth: '300px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                }}
            >
                {record.content}
            </Typography>
        </Tooltip>
    );
};

const CustomDatagrid = ({ ...props }) => (
    <Datagrid
        {...props}
        bulkActionButtons={false}
        sx={{
            '& .RaDatagrid-headerCell': {
                backgroundColor: '#f5f5f5',
                fontWeight: 'bold',
            },
            '& .RaDatagrid-row': {
                '&:hover': {
                    backgroundColor: '#f9f9f9',
                },
            },
        }}
    >
        <ReferenceField
            source="participantId"
            reference="participants"
            link="show"
        >
            <WrapperField>
                <Box display="flex" flexDirection="column">
                    <TextField source="phoneNumber" />
                    <Typography variant="caption" color="textSecondary">
                        <TextField source="firstName" /> <TextField source="lastName" />
                    </Typography>
                </Box>
            </WrapperField>
        </ReferenceField>
        <ContentField source="content" label="Message" />
        <TextField 
            source="method" 
            label="Method"
            sx={{
                '& .MuiChip-root': {
                    borderRadius: '4px',
                    textTransform: 'capitalize',
                },
            }}
        />
        <StatusField source="status" />
        <NumberField
            source="amount"
            label="Cost"
            options={{
                style: 'currency',
                currency: 'UGX',
                minimumFractionDigits: 2
            }}
        />
        <DateField
            source="createdAt"
            label="Sent At"
            showTime
            options={{
                weekday: 'short',
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }}
        />
    </Datagrid>
);

export const ListBulk = (props) => (
    <List
        {...props}
        title="Bulk Messages"
        sort={{ field: 'createdAt', order: 'DESC' }}
        filters={listFilters}
        actions={<ListActions />}
        sx={{
            '& .RaList-main': {
                boxShadow: 1,
                borderRadius: 2,
                overflow: 'hidden',
            },
        }}
    >
        <CustomDatagrid rowClick="show" />
    </List>
);